/* Imports */
@import url('https://fonts.googleapis.com/css2?family=Cabin:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* Variables */
:root {
    /* Color */
    --primary: #7DCFB6;
    --secondary: #A7A5C6;
    --link: #FBD1A2;
    --dark: #272D2D;
    --error: #FF3333;
    --warning: #FFD953;
    --success: #5CF04F;
    --white: #FFFFFF;
    --lightgrey: #CCCCCC;
    --grey: #6F6F6F;
    --black: #000000;

    --black-trans1: rgba(0, 0, 0, 20%);
    --black-trans2: rgba(0, 0, 0, 40%);

    --primary-trans: rgba(125, 207, 182, 50%);

    --primary-dark: #64A692;
    --secondary-dark: #86849E;
    --link-dark: #C9A782;
    --error-dark: #CC2929;
    --warning-dark: #998232;
    --success-dark: #4AC03F;

    /* Box Shadow */
    --box-shadow-lvl1: 0rem 0.25rem 0.438rem 0rem var(--black-trans1);
    --box-shadow-lvl2: 0rem 0.313rem 0.625rem 0rem var(--black-trans2);

    /* Font */
    --cabin: 'Cabin', sans-serif;
    --raleway: 'Raleway', sans-serif;

    --heading: 6.75rem; /* 108px */
    --section: 4.75rem; /* 76px */
    --subhead1: 3.5rem; /* 56px */
    --subhead2: 2.313rem; /* 37px */
    --subhead3: 1.813rem; /* 29px */
    --subhead4: 1.375rem; /* 22px */
    --body: 1rem; /* 16px */
    --caption: 0.688rem; /* 11px */

    --wt-100: 100;
    --wt-200: 200;
    --wt-300: 300;
    --wt-400: 400;
    --wt-500: 500;
    --wt-600: 600;
    --wt-700: 700;
    --wt-800: 800;
    --wt-900: 900;

    /* Other */
    --transition: ease-in-out 200ms;
}

/* Resets */
*, *::after, *::before {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    cursor: default;
}

img, picture {
    width: 100%;
}

ul, ol, il, a {
    text-decoration: none;
    list-style: none;
}

h1, h2, h3, h4, h5, h6, p {
    font-weight: var(--wt-400);
}

input {
    cursor: text;
    background: none;
    border: none;
}

input:focus {
    outline: none;
}

a {
    color: var(--dark);
}

/* Utils */
.container {
    margin: 0 3rem;
}

.bin {
    margin: 1rem 0;
}

.cabin {
    font-family: var(--cabin);
}

.raleway {
    font-family: var(--raleway);
}

.heading {
    font-size: var(--heading);
}

.section {
    font-size: var(--section);
}

.subhead1 {
    font-size: var(--subhead1);
}

.subhead2 {
    font-size: var(--subhead2);
}

.subhead3 {
    font-size: var(--subhead3);
}

.subhead4 {
    font-size: var(--subhead4);
}

.body {
    font-size: var(--body);
}

.caption {
    font-size: var(--caption);
}

.text-primary {
    color: var(--primary);
}

.text-secondary {
    color: var(--secondary);
}

.flex {
    display: flex;
}

.flex-column {
    flex-direction: column;
}

.flex-row {
    flex-direction: row;
}

.flex-wrap {
    flex-wrap: wrap;
}

.space-between {
    justify-content: space-between;
}

.space-around {
    justify-content: space-around;
}

.align-center {
    align-items: center;
}

.justify-center {
    justify-content: center;
}

.text-center {
    text-align: center;
}

.sticky {
    position: fixed;
    top: 0;
    width: 100%;
}
  
.sticky + .content {
    padding-top: 102px;
}

/* Components */

.button {
    cursor: pointer;
    background: var(--primary);
    color: var(--white);
    border: none;
    border-radius: 20px;
    padding: 1.875rem 3.125rem;
    font-family: var(--cabin);
    font-size: var(--subhead2);
    transition: var(--transition);
}

.button:hover {
    background: var(--primary-dark);
    color: var(--white);
    border: none;
    border-radius: 20px;
    padding: 1.875rem 3.125rem;
    font-family: var(--cabin);
    font-size: var(--subhead2);
}

/* Media Queries */
@media only screen and (min-width: 1px) and (max-width: 400px) {
    :root {
        font-size: 7px;
    }
}

@media only screen and (min-width: 401px) and (max-width: 600px) {
    :root {
        font-size: 8px;
    }
}

@media only screen and (min-width: 601px) and (max-width: 768px) {
    :root {
        font-size: 10px;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
    :root {
        font-size: 12px;
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1600px) {
    :root {
        font-size: 14px;
    }
}

@media only screen and (min-width: 1601px) {
    :root {
        font-size: 16px;
    }
}

/* @media only screen and (min-width: 1px) and (max-width: 768px) {

}

@media only screen and (min-width: 769px) and (max-width: 1024px) {

}

@media only screen and (min-width: 1025px) {

} */