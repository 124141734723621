.car-details {
    padding-top: 3rem;
    gap: 3rem;
}

.head-row {
    gap: 1rem;
}

.images {
    flex-wrap: wrap;
    gap: 2rem;
}

.images > img {
    width: 25rem;
    border-radius: 1rem;
}

.details {
    flex-wrap: wrap;
    gap: 2rem;
}

.paper {
    box-shadow: var(--box-shadow-lvl1);
    width: 37.5rem;
    padding: 2rem;
    gap: 1rem;
    border-radius: 30px;
}

.img-paper {
    box-shadow: var(--box-shadow-lvl1);
    border-radius: 30px;
}

.text-card-space {
    gap: 0.35rem;
}