.footer {
    background: var(--dark);
    color: var(--white);
    margin-top: 3rem;
    padding: 3rem 10rem;
}

.footer-logo-container > img {
    height: 5rem;
}

li > h4 {
    color: var(--primary);
}

.gap {
    gap: 2rem;
}

.gap-y {
    gap: 2rem 0;
}

.bottom-container {
    gap: 1rem;
}

.text-gap {
    gap: 1rem;
}

/* Media Queries */
@media only screen and (max-width: 1200px) {
    .footer {
        padding-top: 5rem;
    }

    .footer > .container {
        margin: 0 0.1rem;
    }

    .footer-bottom {
        margin-top: 2rem;
    }
    
    .footer-bin{
        display: flex;
        flex-direction: column;
        gap: 3rem;
        align-items: center;
        text-align: center;
    }
}