/* Imports */
/* @import url('https://fonts.googleapis.com/icon?family=Material+Icons'); */
@import url('https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp');

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  /* font-size: 24px;  Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
   text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

.material-icons-outline {
  font-family: 'Material Icons Outlined';
  font-weight: normal;
  font-style: normal;
  /* font-size: 24px;  Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
   text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

.icon-32 {
  font-size: 32px;
}

.icon-64 {
  font-size: 64px;
}

.icon-128 {
  font-size: 128px;
}

/* Media Queries */
@media only screen and (min-width: 1px) and (max-width: 400px) {
  .icon-32 {
    font-size: 16px;
  }
  
  .icon-64 {
    font-size: 32px;
  }
  
  .icon-128 {
    font-size: 60px;
  }
}

@media only screen and (min-width: 401px) and (max-width: 600px) {
  .icon-32 {
    font-size: 24px;
  }
  
  .icon-64 {
    font-size: 48px;
  }
  
  .icon-128 {
    font-size: 96px;
  }
}