/* Hero */
.hero {
    margin: 5rem 0;
}

/* Search */
.searchBin {
    gap: 3rem 8rem;
    flex-wrap: wrap;
}

.searchCard {
    cursor: pointer;
    box-shadow: var(--box-shadow-lvl1);
    width: 37.5rem;
    padding: 1.25rem;
    gap: 1rem;
    border-radius: 30px;
    transition: var(--transition);
}

.searchCard:hover {
    box-shadow: var(--box-shadow-lvl2);
    width: 37.5rem;
    padding: 1.25rem;
    gap: 1rem;
    border-radius: 30px;
}

.searchCard > span {
    cursor: pointer;
}

/* SaleRep */
.salerep {
    margin-top: 3rem;
}

.salerep-container {
    margin-top: 2rem;
    flex-wrap: wrap;
}

.input-container {
    gap: 3rem;
}

.div {
    gap: 3rem;
}

.sale-image {
    height: 31.25rem;
    width: 31.25rem;
    border-radius: 2rem;
    /* object-fit: contain; */
}

/* Finance */
.finance {
    margin-top: 3rem;
    gap: 3rem;
}

/* Responsive */
@media only screen and (max-width: 1600px) {
    .div {
        flex-direction: column;
    }
    
    .sale-image {
        margin-top: 5rem;
    }
}