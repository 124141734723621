/* .searchBar {
    background-color: aqua;
} */

.searchBar {
    color: var(--dark);
}

.input {
    background: var(--white);
    box-shadow: var(--box-shadow-lvl2);
    padding: 1.25rem;
    gap: 1rem;
    border-radius: 30px;
}