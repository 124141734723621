.card {
    cursor: pointer;
    box-shadow: var(--box-shadow-lvl1);
    color: var(--dark);
    width: 37.5rem;
    padding: 2rem;
    gap: 2rem;
    border-radius: 30px;
    transition: var(--transition);
}

.card:hover {
    box-shadow: var(--box-shadow-lvl2);
    width: 37.5rem;
    padding: 2rem;
    gap: 2rem;
    border-radius: 30px;
}

.card-container {
    margin: 3rem;
    gap: 3rem;
    flex-wrap: wrap
}

.card-content {
    gap: 1rem;
}

.card-bottom {
    gap: 1rem;
}

.card-title > h2 {
    cursor: pointer;
}

.card-description > h3, .card-text > h3 {
    cursor: pointer;
}

/* .card-image > img {
    cursor: pointer;
    width: 6rem;
    height: 6rem;
    border-radius: 30rem;
    background-color: tomato;
} */

.card-image > img {
    cursor: pointer;
    width: 6rem;
    height: 6rem;
    border-radius: 30rem;
    background-color: var(--lightgrey);
}