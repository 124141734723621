.paper-goal {
    box-shadow: var(--box-shadow-lvl1);
    width: 75%;
    /* width: 37.5rem; */
    padding: 2rem;
    gap: 1rem;
    border-radius: 30px;
}

.paper-text {
    box-shadow: var(--box-shadow-lvl1);
    width: 45rem;
    /* width: 37.5rem; */
    padding: 2rem;
    gap: 1rem;
    border-radius: 30px;
}

/* Header */
.about-team {
    margin: 5rem 0;
    gap: 5rem;
}

.about-header {
    gap: 2rem;
}

.about-image {
    width: 25rem;
}

/* Goals */
.ourgoals {
    margin: 0 0 5rem 0;
    gap: 2rem;
}

.goal-container {
    gap: 3rem;
}

.goal-card {
    gap: 1rem;
}

.goal-card > h1 {
    color: var(--primary);
}