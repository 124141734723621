nav {
    width: 100%;
    padding: 1rem 0;
    background: var(--primary-trans);
    backdrop-filter: blur(0.75rem);
}

.navigation {
    position: sticky;
    top: 0px;
    z-index: 1;
}

.logo-container > a > img {
    height: 5rem;
}

.nav-container {
    flex-direction: row;
    gap: 2rem;
}

.nav-items > a {
    cursor: pointer;
    font-family: var(--raleway);
    font-size: var(--subhead4);
    color: var(--white);
    transition: var(--transition);
    position: relative;
}

.nav-items:hover > a {
    color: var(--secondary);
}

.nav-items > a::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 0.30rem;
    background: var(--secondary);
    border-radius: 10rem;
    bottom: 0;
    left: 0;
    margin: -0.4rem 0;
    /* transform-origin: left; */
    transform: scaleX(0);
    transition: var(--transition);
}

.nav-items:hover > a::before {
    transform: scaleX(1);
}

.nav-icon {
    display: none;
}

.nav-panel {
    display: none;
}

/* Responsive */
@media only screen and (max-width: 1024px) {
    .logo-container > a > img {
        height: 60px;
    }

    .nav-items {
        display: none;
    }

    .nav-icon {
        display: block;
        float: right;
        color: var(--white);
    }

    .nav-icon > span {
        font-size: 25px;
    }

    .nav-panel {
        display: none;
    }

    .show {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 1rem;
    }

    .nav-panel > .nav-items {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .nav-panel > .nav-items > a {
        font-size: 25px;
    }
}